import { v4 as uuid } from "uuid";

const { fileTypes, colors } = require("./Lists");

export const getFileType = (fileName) => {
  var path;
  fileTypes.forEach((item) => {
    if (item.dbPath === fileName) path = item.path;
  });
  return path ? "/" + path : "";
};

export const isNumber = (text, getMessage) => {
  text += "";
  let test = /^\d+$/.test(text);
  if (getMessage) return test ? null : "Only number is allowed";
  return test;
};

export const findObj = (value, arrJson, key) => {
  if (!value || !key || !arrJson) return -1;
  for (let i = 0; i < arrJson.length; i++) {
    const element = arrJson[i][key];
    if (element === value) return i;
  }
  return -1;
};

export const generateUniqueId = () => {
  const unique_id = uuid();
  return unique_id;
};
