import firebase from "firebase/app";
import "firebase/storage";
import "firebase/database";
import "firebase/auth";

import { uid, userName } from "../App";
import { APP_NAME } from "../util/constants";
var firebaseConfig = {
  apiKey: "AIzaSyDe9yym0sxHeGXEFmp946CYIt-_LwNzdiM",
  authDomain: "meta-doc.firebaseapp.com",
  projectId: "meta-doc",
  storageBucket: "meta-doc.appspot.com",
  messagingSenderId: "117618408677",
  appId: "1:117618408677:web:8886e4084c524b1f33e80c",
  databaseURL:
    "https://meta-doc-default-rtdb.asia-southeast1.firebasedatabase.app/",
};
// Initialize Firebase
try {
  firebase.initializeApp(firebaseConfig);
} catch (error) {
  //console.log(error);
}
export const db = firebase;

var firepadRef = firebase.database().ref().child(APP_NAME);
export var storageRef = firebase.storage().ref();
export const createRoom = (roomId) => {
  try {
    firepadRef = firepadRef.child(roomId);
    firepadRef.child("data").child("settings").set({
      admin: uid,
      meetingTime: new Date().getTime(),
      userName: userName,
    });
  } catch (error) {
    //console.log(error);
  }
};
export const joinRoom = (roomId) => {
  firepadRef = firepadRef.child(roomId);
  storageRef = storageRef.child(`chat/${roomId}`);
};

const auth = firebase.auth();
export { auth };

export default firepadRef;
