import React from "react";
import Icon from "@material-tailwind/react/Icon";
import useFirestore from "../hooks/useFirestore";
import Button from "@material-tailwind/react/Button";
import TextEditor from "../components/TextEditor";
import { useNavigate } from "react-router-dom";
import { logout } from "../server/auth";

export default function Docs({ session, id }) {
  const router = useNavigate();
  const { docs } = useFirestore(id);

  if (!session) return null;
  //console.log(docs);
  if (docs && docs === "404") {
    router.replace("/");
  }
  return (
    <>
      <header
        style={{ height: 54, minWidth: 900 }}
        className="bg-white sticky top-0 z-50 flex justify-between items-center "
      >
        <span
          onClick={() => window.open("https://sanjayapps.com/docs", "_self")}
          className="cursor-pointer"
        >
          <Icon name="description" size="3xl" color="blue" />
        </span>

        <div className="flex-grow px-2 p-3">
          {docs && docs !== "404" && <h2>{docs.fileName}</h2>}

          {/* <div className="flex items-center text-sm space-x-1 -ml-1 h-8 text-gray-600">
            <p className="option">File</p>
            <p className="option">Edit</p>
            <p className="option">View</p>
            <p className="option">Insert</p>
            <p className="option">Format</p>
            <p className="option">Tools</p>
          </div> */}
        </div>
        <Button
          color="lightBlue"
          buttonType="filled"
          size="regular"
          className="hidden md:inline-flex h-10"
        >
          <Icon name="people" size="md" />
          Share
        </Button>
        <img
          className="cursor-pointer rounded-full h-10 w-10 ml-2"
          src={session.image}
          onClick={() => logout()}
          alt="user logout"
        />
      </header>
      {<TextEditor id={id} userId={session.email} />}
    </>
  );
}
