import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { storeData } from "../hooks/StoreDB";
import { getData } from "../hooks/GetDB";
import { findObj } from "../files/util";
import { fileTypes } from "../files/Lists";
import { Editor } from "react-draft-wysiwyg";
function TextEditor({ id, userId }) {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const path = fileTypes[findObj("docs", fileTypes, "path")].dbPath;
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    storeData(convertToRaw(editorState.getCurrentContent()), {
      emailId: userId,
      id,
      path,
    });
  };

  React.useEffect(() => {
    //console.log("inside useeffect");
    getData({ id, emailId: userId, path }).then((docs) => {
      //console.log("inside func");
      if (docs && docs.data)
        setEditorState(
          EditorState.createWithContent(convertFromRaw(docs.data))
        );
    });
  }, [id, path, userId]);
  //console.log();
  return (
    <div className="bg-[#F8F9FA] top  pb-16">
      <Editor
        toolbarClassName="editor-toolbar-top flex sticky top-0 z-50 !justify-center mx-auto"
        editorClassName="mt-6 bg-white shadow-lg max-w-5xl mx-auto mb-12 border p-6 page-height"
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
    </div>
  );
}

export default TextEditor;
