exports.fileTypes = [
  { name: "Docs", dbPath: "docs", path: "docs", image: "/app_icon.png" },
  {
    name: "Worksheet",
    dbPath: "worksheet",
    path: "worksheet",
    image: "/workbook.png",
  },
  { name: "Forms", dbPath: "form", path: "form", image: "/forms.png" },
  { name: "Files", dbPath: "files", path: "files", image: "/folder.png" },
  { name: "MetaCalls", dbPath: "", path: "metacalls", image: "/metacalls.png" },
  { name: "Share", dbPath: "", path: "share", image: "/share.png" },
  { name: "Chat", dbPath: "", path: "chat", image: "/chat.png" },
  { name: "LetsSocially", dbPath: "", path: "social", image: "/social.png" },
  { name: "Emoji", dbPath: "", path: "emoji", image: "/emoji.png" },
  { name: "Covid", dbPath: "", path: "Covid", image: "/covid.png" },
];
